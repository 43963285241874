import React from 'react';
import { graphql } from 'gatsby';
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next';

import SEO from 'components/SEO';
import Link from 'components/Link';
import Section from 'components/Section';
import useSiteMetadata from 'hooks/useSiteMetadata';

export default function CopyrightPage() {
  const { t } = useTranslation();

  const site = useSiteMetadata();
  const title = t('common:copyright_claims');

  return (
    <>
      <SEO
        title={`${title} - ${site.title}`}
        description={t('copyright:respect_rights')}
        keywords={title}
      />

      <Section>
        <div className="max-w-3xl mx-auto">
          <div className="text-base">
            <h1 className="text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              {title}
            </h1>
            <p className="mt-8 text-gray-500">
              {t('copyright:respect_rights')}
            </p>
            <p className="mt-4 text-gray-500">
              {t('common:last_updated', { date: '10/05/2021' })}
            </p>

            <div className="mt-5 prose prose-blue text-gray-500">
              <ul>
                <li>{t('copyright:respect_rights')}</li>
                <li>
                  <Trans
                    i18nKey="copyright:infringment_tos"
                    components={[<Link href="/terms" />]}
                  />
                </li>
                <li>{t('copyright:users_not_allowed')}</li>
                <li>{t('copyright:may_remove_content')}</li>
                <li>{t('copyright:dmca_comply')}</li>
                <li>
                  <Trans
                    i18nKey="copyright:send_email_notice"
                    values={{ email: site.copyright_email }}
                    components={[<a href={`mailto:${site.copyright_email}`} />]}
                  />
                </li>
                <li>
                  {t('copyright:notice_include')}
                  <ol>
                    <li>{t('copyright:notice_1')}</li>
                    <li>{t('copyright:notice_2')}</li>
                    <li>{t('copyright:notice_3')}</li>
                    <li>{t('copyright:notice_4')}</li>
                    <li>{t('copyright:notice_5')}</li>
                    <li>{t('copyright:notice_6')}</li>
                  </ol>
                </li>
                <li>{t('copyright:notice_response')}</li>
              </ul>
            </div>
          </div>
        </div>
      </Section>
    </>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "copyright", "videoproc"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
